
import {
    request
} from "./http"

//发送短信
export const sms = (data) => {
    return request({
        url: '/api/sms/index',
        method: 'POST',
        data
    })
}

//招聘流程
export const order = (data) => {
    return request({
        url: '/api/getOrder',
        method: 'POST',
        data
    })
}

//我的简历
export const myResume = (data) => {
    return request({
        url: '/api/getMyResume',
        method: 'POST',
        data
    })
}

//获取图片
export const images = (data) => {
    return request({
        url: '/api/getZpshoolRecruitmentImg',
        method: 'POST',
        data
    })
}


// 提交简历
export const saveResume = (data) => {
    return request({
        url: '/api/saveResume',
        method: 'POST',
        data
    })
}

// 登录
export const login = (data) => {
    return request({
        url: '/api/login',
        method: 'POST',
        data
    })
}

// ststoken
export const ststoken = (data) => {
    return request({
        url: '/api/oss/ststoken',
        method: 'POST',
        data
    })
}

// 所有职位
export const position = (data) => {
    return request({
        url: '/api/getPosition',
        method: 'POST',
        data
    })
}

//招聘列表
export const info = (data) => {
    return request({
        url: '/api/getInfo',
        method: 'POST',
        data
    })
}

//获取招聘信息
export const recruitInfo = (data) => {
    return request({
        url: '/api/getIdToInfo',
        method: 'POST',
        data
    })
}
