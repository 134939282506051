import {
    config
} from './config';
import axios from 'axios'
import VueCookies from 'vue-cookies'
import { Message } from "element-ui"
export const request = ({
    url = '',
    data = {},
    method = 'get'
}) => {
    return new Promise((resolve, reject) => {
        _request(url, resolve, reject, data, method);
    });
};
const _request = (url, resolve, reject, data = {}, method = 'GET') => {
    axios({
        url: config.baseUrl + url,
        data: data,
        method: method,
        timeout: 300000,
        headers: {
            'Authorization': VueCookies.get("token"),
            'content-type': 'application/json',
        },
    }).then(res => {
        if (res.data.code == 10000) {
            resolve(res.data);
        } else {
            Message.error(res.data.message)
            reject(res.data)
        }
    }).catch(error => {
        reject(error);
    });
};
